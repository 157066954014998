<template>
  <div ref="ModalBoxRef">
    <a-modal
      v-model:visible="visible"
      :onCancel="handleCancel"
      @ok="handleOk"
      :getContainer="() => ModalBoxRef"
      centered
    >
      <p class="title">编辑公时</p>
      <a-divider />
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading"  @click="handleOk">发送</a-button>
      </template>
      <a-form :model="formState"  :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" ref="formRef">
        <a-form-item label="飞行开始时间" required name="start">
          <a-date-picker
            v-model:value="formState.start"
            :disabled-date="disabledStartDate"
            show-time
            format="YYYY-MM-DD HH:mm"
            placeholder="飞行开始时间"
          />
        </a-form-item>
        <a-form-item label="飞行结束时间" required name="end">
          <a-date-picker
            v-model:value="formState.end"
            :disabled-date="disabledEndDate"
            show-time
            format="YYYY-MM-DD HH:mm"
            placeholder="飞行结束时间"
          />
        </a-form-item>
        <a-form-item label="飞行记录" >
            <a-input v-model:value.trim="formState.flyContent" placeholder="" allowClear></a-input>
        </a-form-item>
        <a-form-item label="飞行记录图片" >
          <a-upload-dragger
            class="cover-file"
            :file-list="fileList"
            :remove="removeFile"
            :customRequest="selfUpload"
            @change="handleChange"
          >
            <p class="ant-upload-drag-icon" v-show="!formState.flyPic">
              <plus-outlined></plus-outlined>
            </p>
            <img :src="formState.flyPic" v-show="formState.flyPic" class="cover-img" />
          </a-upload-dragger>
            <!-- <a-input v-model:value.trim="formState.flyPic" placeholder="" allowClear></a-input> -->
        </a-form-item>
       
        
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, toRefs, onMounted, nextTick } from 'vue';
import api from '@/services';
import { PlusOutlined } from '@ant-design/icons-vue';
import moment from 'moment';
import { message } from 'ant-design-vue';
import { FormatTime } from '@/utils/tool';
export default defineComponent({
 components: {
    PlusOutlined
  },
  setup(props, context) {
    const loading = ref(false);
    const visible = ref(false);

    const ModalBoxRef = ref();

    const labelCol = reactive({ span: 6, offset: 2 });
    const wrapperCol = reactive({ span: 14 });
    const formState = reactive({
      id: '', // 此条无人机任务 id
      start: '',
      end: '',
      flyContent: '',
      flyPic: '',
    });

    const fileList = ref([]); // 上传图片列表

    const rules = {
      
      start: [
        {
          required: true,
          message: '请输入开始时间',
          type: 'object'
        }
      ],
      end: [
        {
          required: true,
          message: '请输入结束时间',
        }
      ],
    };


    onMounted(() => {});

    const disabledStartDate = startTime => {
      if (!formState.startTime || !formState.endTime) {
        return false;
      }

      return startTime.valueOf() > formState.endTime.valueOf();
    };

    const disabledEndDate = endTime => {
      if (!formState.endTime || !formState.startTime) {
        return false;
      }

      return formState.startTime.valueOf() >= endTime.valueOf();
    };

    const handleStartChange = (date, dateString) => {
      console.log('start date====', date, dateString);
      formState.start = dateString;
    };

    const handleEndChange = (date, dateString) => {
      console.log('end date====', date, dateString);
      formState.end = dateString;
    };

    const showModal = (flag, rowData) => {
      visible.value = true;
      nextTick(() => {
        if (flag) {
          Object.keys(formState).map(key => {
            formState[key] = rowData[key];
          });
          if(rowData.start) {
            formState.start = moment(rowData.start);
            formState.end = moment(rowData.end);
          }
        }
      });
    };

    const formRef = ref();

    const handleOk = async () => {
      formRef.value.validate().then( async () => {
        loading.value = true;
        formState.start = `${FormatTime(formState.start, 'YYYY-MM-DD HH:mm')}:00`;
        formState.end = `${FormatTime(formState.end, 'YYYY-MM-DD HH:mm')}:59`;
        let params = {
          ...formState,
        };

        const { success } = await api.updateUavTask(params);
        console.log(success)
        if (success) {
          message.success(`编辑成功！`);
          context.emit('addOrUpdateSuccess');
          visible.value = false;
        }
        loading.value = false;
      })
      .catch(error => {
        message.error('请输入')
      });
    };

    const handleCancel = () => {
      visible.value = false;
      clearForm();
    };

    const clearForm = () => {
      Object.keys(formState).map(key => {
        formState[key] = '';
      });
    };

    // 删除
    const removeFile = () => {
      formState.flyPic = '';
    };

    // base64转换
    const selfUpload = ({ file }) => {
      new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
          formState.flyPic = fileReader.result;
        };
      });
    };

    // 修改上传状态 和 限制上传数量
    const handleChange = info => {
      // const isLt2M = info.file.size / 1024 / 1024 < 0.2;
      // if (!isLt2M) {
      //   message.error('Image must smaller than 0.2MB!');
      //   return;
      // }
      let resFileList = [...info.fileList];
      resFileList = resFileList.slice(-1);
      fileList.value = resFileList;
      if (fileList.value.length) fileList.value[0].status = 'done';
    };
    return {
      formRef,
      disabledStartDate,
      disabledEndDate,
      handleStartChange,
      handleEndChange,
      labelCol,
      wrapperCol,
      formState,
      loading,
      visible,
      showModal,
      handleOk,
      handleCancel,
      ModalBoxRef,
      clearForm,
      rules,

      fileList,
      removeFile,
      selfUpload,
      handleChange
    };
  }
});
</script>

<style lang="less" scoped>
:deep(.ant-modal-body) {
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .ant-form {
    color: blue;
    .ant-form-item {
      .ant-upload-drag-icon {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :deep(.anticon-plus) {
          color: #d4d4d4;
          font-size: 24px;
        }
      }
      .cover-img {
        height: 148px;
        border-radius: 10px;
      }
    }
  }
}
.ant-calendar-picker {
  width: 100%
}


</style>
