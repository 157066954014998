/**
 * description： 格式化日期
 * description： Format Date
 * @param {Date} Time   时间
 * @param {String} Type 格式类型
 */
export const FormatTime = (Time, Type = 'YYYY-MM-DD') => {
  if (!Time) return '';
  Time = new Date(Time);
  let Staging = [
    Time.getFullYear(),
    Time.getMonth() + 1,
    Time.getDate(),
    Time.getHours(),
    Time.getMinutes(),
    Time.getSeconds()
    // "sm": Time.getMilliseconds()
  ];
  let index = 0;
  const resYear = /([a-zA-Z])\1{3}/g;
  const reg = /([a-zA-Z])\1/g;
  Staging = Staging.map(item => {
    if (item < 10) {
      item = `0${item}`;
    }
    return String(item);
  });
  Type = Type.replace(resYear, () => String(Staging[0]));
  Type = Type.replace(reg, () => String(Staging[(index += 1)]));
  return Type;
};

/**
 * 截取视频方法
 *
 * @param {HTMLVideoElement} video 视频dom
 * @param {number} timePoint 截取时间点
 * @param {(imgUrl) => void} callback  截取成功回调
 * @param {Object} options  可选项
 * @param {number} options.imgQuality 图片质量 默认为3
 */
export const captureVideo = (video, timePoint, callback, options = {}) => {
  // 生成图片url
  let videoWidth = 0;
  let videoHeight = 0;

  const ndVideo = document.createElement('video');
  const ndCanvas = document.createElement('canvas');
  const context = ndCanvas.getContext('2d');

  ndVideo.currentTime = timePoint;
  ndVideo.addEventListener('canplay', () => {
    videoWidth = ndVideo.videoWidth;
    videoHeight = ndVideo.videoHeight;
    ndCanvas.width = videoWidth;
    ndCanvas.height = videoHeight;

    context.drawImage(ndVideo, 0, 0, videoWidth, videoHeight, 0, 0, videoWidth, videoHeight);

    const dataURL = ndCanvas.toDataURL('image/jpeg', options.imgQuality);
    callback && callback(dataURL);
  });

  ndVideo.src = video.src;
};

/** -----your logic------ */
/**
 * 截取图片
 *
 * @param {number} [number=1]
 * @param {HTMLVideoElement} video
 */
export const genRandomCapture = (randomNum, video, func) => {
  captureVideo(video, randomNum, src => {
    // const ndPosterBox = document.querySelector('.poster-list');
    // const ndImg = new Image();
    // ndImg.src = src;
    // ndPosterBox.appendChild(ndImg);
    func(src);
  });
};

export const getQueryVariable = variable => {
  let query = window.location.search.substring(1);
  let vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};

export const  RandomNum = (Min, Max)=> {
  var Range = Max - Min;
  var Rand = Math.random();
  if(Math.round(Rand * Range)==0){             
      return Min + 1;
  }
  var num = Min + Math.round(Rand * Range);
  return num;
}
